.ar-modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  margin: 0;
  align-items: center;
  justify-content: center;
}

.ar-modal-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  border: 1px solid #888;
  width: 330px;
  min-height: 560px;
  border-radius: 24px;
}

.ar-modal-close-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ar-modal-content-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.ar-modal-close {
  transition: all 0.2s ease-in-out;
  padding-top: 10px;
  padding-right: 10px;
}

.ar-modal-close:hover,
.ar-modal-close:focus {
  transform: scale(1.4);
}

.ar-modal-title {
  font-family: Geneva, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  padding-left: 48px;
  padding-right: 48px;
  margin-top: 24px;
}

.ar-modal-qr {
}

.ar-modal-qr-container {
  position: relative;
}

.ar-modal-qr-container:before,
.ar-modal-qr-container:after,
.ar-modal-qr-container > :first-child:before,
.ar-modal-qr-container > :first-child:after {
  position: absolute;
  width: 32px;
  height: 32px;
  border-color: #0025e6;
  border-style: solid;
  content: " ";
}
.ar-modal-qr-container:before {
  top: 0;
  left: 0;
  border-width: 1px 0 0 1px;
  border-top-left-radius: 4px;
}
.ar-modal-qr-container:after {
  top: 0;
  right: 0;
  border-width: 1px 1px 0 0;
  border-top-right-radius: 4px;
}
.ar-modal-qr-container > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 1px 1px 0;
  border-bottom-right-radius: 4px;
}
.ar-modal-qr-container > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 1px 1px;
  border-bottom-left-radius: 4px;
}

.ar-modal-qr-img {
  width: 220px;
  height: 220px;
  padding: 24px;
}

.ar-modal-prompt-text {
  font-family: Geneva, sans-serif;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.2;
  text-align: center;
  color: #0025e6;
  padding-right: 20%;
  padding-left: 20%;
}

.ar-modal-help-text {
  font-family: Geneva, sans-serif;
  font-weight: normal;
  text-decoration: underline;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.ar-modal-help {
  padding-right: 48px;
  padding-left: 48px;
  margin-top: 16px;
  margin-bottom: 16px;
}

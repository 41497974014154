.content-container {
    min-height: 100vh;
    min-width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.content-title {
    font-family: "GraphikSemiBold", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #202020;
    margin-top: 26px;
    text-align: left;
    text-transform: uppercase;
}

.object-container {
    background-color: #c9c9c9;
}

.button-container {
    margin-top: 16px;
}


.not-found-container {
    min-height: 100vh;
    min-width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found-hero-text {
    font-family: "GraphikSemiBold", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 88px;
    text-align: center;
    margin: 16px;
    color: #202020;
}

.not-found-prompt {
    font-family: "Graphik", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #202020;
    margin: 0;
}

.ar-button-help-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.ar-button-help-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Geneva, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  height: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #000000;
}

.ar-button-help-text-underlined {
  text-decoration: underline;
}

.self-contain-container {
    min-height: 100vh;
    min-width: 100vh;
    background-color: #EDEDED;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.self-contain-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 267px;
}

.self-contain-inner-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.self-contain-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.self-contain-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.self-contain-title {
    font-family: "GraphikSemiBold", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #202020;
    padding-top: 16px;
}

.self-contain-prompt {
    font-family: "Graphik", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    display: flex;
    color: #202020;
    margin-top: 8px;
}

.self-contain-qr {
    margin-top: 16px;
    margin-bottom: 16px;
}

.self-contain-ar-icon {
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.self-contain-ar-icon-mobile {
    position: absolute;
    top: 16px;
    left: 16px;
}

.self-contain-arrow {
    position: absolute;
    right: 70px;
    top: 60px
}

.self-contain-button {
    margin-top: 16px;
}

.ar-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  height: 48px;

  background: #0025e6;
  border-radius: 8px;
  border: none;
}

.ar-button-inactive {
  background: #9da1b4;
}

.ar-button:hover {
  background-color: rgba(0, 37, 230, 0.82);
}

.ar-button:focus {
  outline: 2px solid rgba(170, 170, 170, 0.32);
}

.ar-button-text {
  padding: 6px;

  font-family: Geneva, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;

  color: #ffffff;
}

.approval-ar-button-text {
  padding: 6px;

  font-family: Geneva, sans-serif;
  font-style: normal;
  font-size: 42px;
  line-height: 1.5;

  color: #ffffff;
}

.ar-button-icon {
  padding: 6px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Graphik;
  src: local("Graphik"),
  url("./fonts/Graphik/Graphik-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: GraphikSemiBold;
  src: local("GraphikSemiBold"),
  url("./fonts/Graphik/Graphik-Semibold.ttf") format("truetype");
  font-weight: 600;
}
